const histories = [
	{
		text: 'Users',
		align: 'center',
		sortable: false,
		value: 'user'
	},
	{
		text: 'User Agent',
		align: 'center',
		sortable: false,
		value: 'agent_username'
	},
	{ text: 'Date', value: 'date', align: 'center' },
	{ text: 'Amount', value: 'amount', align: 'center' },
	{ text: 'Old Balance', value: 'old_balance', align: 'center' },
	{ text: 'New Balance', value: 'new_balance', align: 'center'},
];

export default histories;
